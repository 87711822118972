/* You can add global styles to this file, and also import other style files */
@import 'vsp.variables.scss', 'vsp.mixins.scss', 'vsp.print.scss';
//@import "~bootstrap/dist/css/bootstrap.css";
@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import '../../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid';
// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
// Import Roboot
@import "../../../node_modules/bootstrap/scss/reboot";
@import '../../assets/styles/variables';
@import "@ng-select/ng-select/themes/default.theme.css";

@mixin box-shadow-default() {
  @include box-shadow(0 5px 7px rgba(0, 0, 0, 0.2));
}

$input-message-arrow-size: 7px;
$input-message-arrow-offset-bottom: 16px;
$input-message-arrow-offset-left: 23px;
$border-radius-base: 4px !default;

body {
  font-family: $font-family;
  font-size: $font-family-size;
  line-height: 1.428571429;
  color: #6F7172;
}

*:focus {
  outline: none;
}

// Removes Chrome input background color on autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: $form-field-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  font-family: $font-family;
  font-size: $font-family-size;
  font-weight: $custom-select-font-weight;
  line-height: $custom-select-line-height;
  color: $font-family-color;
  vertical-align: middle;
  background: $required-and-enabled-select-dropdown-arrows-format;
  background-color: transparent;
  border: 1px solid $color-gray-dark;
  appearance: none;
  cursor: pointer;

  &:hover:not(:focus):not([disabled]) {
    color: $font-family-color !important;
    transition-duration: $form-field-box-shadow-transition-duration;
    box-shadow: $form-field-box-shadow-hover-format;
    border-radius: 3px !important;
    border: $form-field-hover-border-color;
  }

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    display: inline-block;
    width: 100%;
    height: $form-field-height;
    padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
    font-family: $font-family;
    font-size: $font-family-size;
    font-weight: $custom-select-font-weight;
    line-height: $custom-select-line-height;
    vertical-align: middle;
    background: $disabled-select-dropdown-arrows-format;
    border: $disabled-outline-format;
    background-color: $disabled-background-color;
    color: $font-family-color;
    cursor: not-allowed;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  &.required-form-field:not([disabled]) {
    background-color: $required-background-color;
    border: $required-outline-format;
    color: $font-family-color;
    font-family: $font-family;
    font-size: $font-family-size;
  }

  &.required-form-field:focus {
    box-shadow: $required-box-shadow-outline-focus-format;

    > option {
      background-color: $color-white;
    }
  }
}

// This was as part of the angular upgrade to make sure the icons we have are not cutoff.
.mat-icon {
  overflow: visible !important;
}

select:disabled {
  opacity: 1 !important;
  background: $disabled-select-dropdown-arrows-format !important;
  color: $font-family-color !important;
  background-color: $disabled-background-color !important;
  font-family: $font-family;
  font-size: $font-family-size;
}

.action-link {
  color: $form-field-link-color;
  font-size: $font-family-size;
  font-family: $font-family;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $form-field-link-hover-color;
  }

  &:focus {
    text-decoration: underline;
    color: $form-field-link-hover-color;
  }
}

.action-link-no-underline {
  color: $form-field-link-color;

  &:hover {
    cursor: pointer;
  }
}

.mat-form-field {
  // Changing this two lines will cause the error-wrapper to appear in the wrong place
  height: auto !important;
  min-height: 0 !important;

  .mat-form-field-outline {
    height: 36px;
  }

  .mat-input-element:disabled {
    color: $font-family-color;
  }

  .mat-input-element {
    font-size: $font-family-size;
    font-family: $font-family;

    &:focus {
      box-shadow: none;
    }
  }

  .mat-form-field-infix {
    border-top: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-form-field-appearance-outline.mat-form-field-disabled {
  color: $font-family-color;
  font-family: $font-family;
  font-size: $font-family-size;
  cursor: not-allowed;

  .mat-input-element {
    background-color: $disabled-background-color !important;
    cursor: not-allowed;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $disabled-background-color inset !important;
    -webkit-text-fill-color: #58595B !important;
  }

  .mat-form-field-outline {
    background-color: $disabled-background-color;
    border-radius: 3px 3px 3px 3px !important;
    border: $disabled-outline-format !important;
    color: #FFFFFF !important;
  }

  .mat-datepicker-toggle {
    .mat-icon-button {
      cursor: not-allowed;

      .mat-datepicker-toggle-default-icon {
        color: $disabled-date-picker-calendar-icon-color !important;
      }
    }
  }
}

/*
  Invalid field coloring
 */
.mat-form-field.ng-invalid.ng-touched {
  > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-outline {
    background-color: $color-red-4 !important;
    color: $color-red-3 !important;
  }

  &:hover:not(:focus-within) .mat-form-field-outline {
    color: $color-red-3 !important;
    box-shadow: $form-field-invalid-textbox-box-shadow-hover;
    transition-duration: $form-field-box-shadow-transition-duration !important;
    border-radius: 3px !important;
  }

  &:not(:focus-within) .mat-form-field-outline-start {
    border: 1px solid currentColor;
    border-right: none;
  }

  &:not(:focus-within) .mat-form-field-outline-end {
    border: 1px solid currentColor;
    border-left: none;
  }

  // Overrides the global Chrome input background color on autofill property
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: $color-red-3 !important;
    -webkit-box-shadow: 0 0 0 30px $color-red-4 inset !important;
  }

  .mat-input-element {
    color: $color-red-3 !important;
  }
}

.required-form-field:not([disabled]) input.ng-invalid.ng-touched:focus {
  box-shadow: $form-field-invalid-box-shadow-focus;
  transition-duration: $form-field-box-shadow-transition-duration !important;
  border: $form-field-invalid-border;
}

.custom-select.ng-invalid.ng-touched {
  background-color: $color-red-4 !important;
  border: $form-field-invalid-border;

  &:hover:not([disabled]):not(:focus-within) {
    box-shadow: $form-field-invalid-box-shadow-hover;
    transition-duration: $form-field-box-shadow-transition-duration !important;
    border: $form-field-invalid-border;
  }

  &:focus {
    box-shadow: $form-field-invalid-box-shadow-focus;
    transition-duration: $form-field-box-shadow-transition-duration !important;

    > option {
      background-color: $color-white;
    }
  }
}

.mat-radio-group.ng-invalid.ng-touched > .mat-radio-button {
  > .mat-radio-label > .mat-radio-container > .mat-radio-outer-circle {
    background-color: $color-red-4 !important;
    border: $form-field-invalid-border;
  }

  &:hover > .mat-radio-label > .mat-radio-container > .mat-radio-outer-circle {
    box-shadow: $form-field-invalid-box-shadow-hover;
    transition-duration: $form-field-box-shadow-transition-duration !important;
  }
}

.mat-checkbox.ng-invalid.ng-touched {
  > .mat-checkbox-layout > .mat-checkbox-inner-container > .mat-checkbox-background {
    background-color: $color-red-4 !important;
    border: $form-field-invalid-border;
    border-radius: 5px;
  }

  &:hover:not(:focus) > .mat-checkbox-layout > .mat-checkbox-inner-container > .mat-checkbox-background {
    box-shadow: $form-field-invalid-box-shadow-hover;
    transition-duration: $form-field-box-shadow-transition-duration !important;
  }

  &:focus > .mat-checkbox-layout > .mat-checkbox-inner-container > .mat-checkbox-background {
    box-shadow: $form-field-invalid-box-shadow-focus;
    transition-duration: $form-field-box-shadow-transition-duration !important;
  }
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) {
  &:hover:not(:focus-within) .mat-form-field-outline {
    color: $form-field-hover-color !important;
    box-shadow: $form-field-box-shadow-rgb-format;
    border-radius: 3px !important;
  }

  .mat-form-field-outline {
    color: $color-gray-dark !important;
  }

  .mat-form-field-outline-start {
    border-radius: 3px 0 0 3px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 3px 3px 0 !important;
  }

  .mat-datepicker-toggle-default-icon {
    color: $enabled-date-picker-calendar-icon-color !important;
  }
}

.required-form-field-label {
  font-family: $font-family;
  font-size: 12px;
  color: $color-gray-dark;
}

.required-form-field:not([disabled]) {
  .custom-select {
    background-color: $required-background-color;
    border: $required-outline-format;
    color: $font-family-color;
    font-family: $font-family;
    font-size: $font-family-size;

    &:focus {
      box-shadow: $required-box-shadow-outline-focus-format;
    }

    &:focus-within {
      box-shadow: $required-box-shadow-outline-focus-format;
    }
  }
}

.required-form-field > form > .custom-select:disabled {
  background-color: $disabled-background-color !important;
  border: $disabled-outline-format !important;
}

.required-mat-form-field > form > .mat-form-field-disabled > div > div > .mat-form-field-outline {
  background-color: $disabled-background-color !important;
  border-radius: 3px 3px 3px 3px !important;
  border: $disabled-outline-format !important;
  color: #FFFFFF !important;
}

.required-mat-form-field:not(.mat-form-field-disabled) {
  color: $font-family-color;
  font-family: $font-family;
  font-size: $font-family-size;

  .mat-form-field-outline {
    background-color: $required-background-color;
    border-radius: 3px 3px 3px 3px !important;
    color: $required-outline-color !important;
  }

  .mat-datepicker-toggle-default-icon {
    color: $required-date-picker-calendar-icon-color !important;
  }

  // Overrides the global Chrome input background color on autofill property
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: $font-family-color !important;
    -webkit-box-shadow: 0 0 0 30px $required-background-color inset !important;
  }

  &:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $required-outline-color !important;
    background-color: $required-background-color !important;
  }
}

.state-code-extra-formatting {
  margin-top: 15px;
  height: $form-field-height;
}

// Mock Container - Used to show FPO styling
.container-mock {
  background-image: $fpoImage;
}

// Mock Container with round borders
.container-mock-round {
  background-image: $fpoImage;
  border-radius: $border-radius-base;
}

.container-white, .container-white-full-column {
  background-color: white;
  border: 1px solid #F4F4F4;
  border-radius: 4px;
  padding: 20px;
}

.container-white-full-column {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 14px;
  padding-right: 14px;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #5D5F60;
}


.vspicon-vsp_arrow_left:before {
  content: "\e902";
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

// TOP

.margin-top-3 {
  margin-top: 3px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-18 {
  margin-top: 18px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

// TOP NEGATIVE
.margin-top-negative-5 {
  margin-top: -5px;
}

.margin-top-negative-10 {
  margin-top: -10px;
}

// LEFT
.margin-left-negative-5 {
  margin-left: -5px;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-45 {
  margin-left: 45px;
}

.margin-left-60 {
  margin-left: 60px;
}

// RIGHT
.margin-right-3 {
  margin-right: 3px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-40 {
  margin-right: 40px;
}

// BOTTOM
.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-9 {
  margin-bottom: 9px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.full-width {
  margin-left: -20px;
  margin-right: -20px;
}

.vsp-container {
  padding: 20px 0;
  height: 100%;
  min-height: 100%;
}

.required-label-asterisk {
  color: $font-family-required-color;
  font-family: $font-family;
  font-size: $font-family-size;
  white-space: pre;
}

.btn-primary {
  color: #fff;
  background-color: $color-blue;
  border-color: #005e9b;
  width: $button-width;
  height: $button-height;
}

.btn-primary:hover, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004f81;
  border-color: #00395d;
}

.btn-primary:disabled, .btn-danger:disabled, btn-info:disabled {
  pointer-events: none;
  background-color: $disabledColor;
  border-color: $disabledColor;
}

[class*=" vspicon-"], [class^="vspicon-"] {
  font-family: vsp-icons !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-blue;
}

a:focus, a:hover {
  color: #003f68;
}


//Validation messages
.input-message {
  //position: absolute;
  padding: 13px 10px;
  border-radius: 7px;
  font-size: 12px;
  line-height: 14px;
  z-index: 999;

  background-color: $color-pink-90;
  color: $color-red;

  min-width: 100px;

  @include box-shadow-default;
  border: 1px solid $color-red;

  word-wrap: break-word;

  p {
    margin: 0;
  }

  &.input-message-bottom {
    &:after, &:before {
      bottom: 100%;
      left: $input-message-arrow-offset-bottom;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 243, 243, 0);
      border-bottom-color: $color-pink;
      border-width: $input-message-arrow-size - 1;
      margin-left: -($input-message-arrow-size - 1);
    }

    &:before {
      border-color: rgba(230, 2, 2, 0);
      border-bottom-color: #E60202;
      border-width: $input-message-arrow-size;
      margin-left: -$input-message-arrow-size;
    }
  }

  &.input-message-left {
    min-height: 43px;

    p {
      margin-top: 2px;
    }

    &:after, &:before {
      left: 100%;
      top: $input-message-arrow-offset-left;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 243, 243, 0);
      border-left-color: $color-pink;
      border-width: $input-message-arrow-size - 1;
      margin-top: -($input-message-arrow-size - 1);
    }

    &:before {
      border-color: rgba(230, 2, 2, 0);
      border-left-color: $color-red;
      border-width: $input-message-arrow-size;
      margin-top: -$input-message-arrow-size;
    }
  }

  &.input-message-right {
    min-height: 43px;

    p {
      margin-top: 2px;
    }

    &:after, &:before {
      right: 100%;
      top: $input-message-arrow-offset-left;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 243, 243, 0);
      border-right-color: $color-pink;
      border-width: $input-message-arrow-size - 1;
      margin-top: -($input-message-arrow-size - 1);
    }

    &:before {
      border-color: rgba(230, 2, 2, 0);
      border-right-color: $color-red;
      border-width: $input-message-arrow-size;
      margin-top: -$input-message-arrow-size;
    }
  }

  &.input-message-top {
    &:after, &:before {
      top: 100%;
      left: $input-message-arrow-offset-bottom;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 243, 243, 0);
      border-top-color: $color-pink;
      border-width: $input-message-arrow-size - 1;
      margin-left: -($input-message-arrow-size - 1);
    }

    &:before {
      border-color: rgba(230, 2, 2, 0);
      border-top-color: #E60202;
      border-width: $input-message-arrow-size;
      margin-left: -$input-message-arrow-size;
    }
  }

  // Warning Message Styling
  &.input-message-warning {
    background-color: $color-orange-90;
    border-color: $color-orange-90;
    color: white;

    &.input-message-bottom {
      &:after {
        border-bottom-color: $color-orange-90;
      }

      &:before {
        border-bottom-color: $color-orange-90;
      }
    }

    &.input-message-right {
      &:after {
        border-right-color: $color-orange-90;
      }

      &:before {
        border-right-color: $color-orange-90;
      }
    }

    &.input-message-left {
      &:after {
        border-left-color: $color-orange-90;
      }

      &:before {
        border-left-color: $color-orange-90;
      }
    }

    .input-message-close-button {
      &:hover {
        color: darken(white, 10%);
      }
    }
  }

  &.input-message-small {
    font-size: 10px;
    padding: 10px 15px 10px 10px;

    .input-message-close-button {
      margin: -3px -7px 0 0;
    }
  }
}

.input-message-close-button {
  cursor: pointer;
  float: right;
  font-size: 10px;

  // pull close button closer to the corner of the message box so it doesn't clash with text
  margin-right: -4px;
  margin-top: -7px;

  &:hover {
    color: darken($color-red, 10%);
  }
}

.vspicon-vsp_close:before {
  content: "\e90d";
}

.form-control.full-width {
  width: 100%;
  margin: 0;
}

.form-control-full-width {
  width: 100%;
}

.form-control:focus {
  background-color: transparent;
}

label {
  margin-bottom: 0;
}

.form-inline {

  label {
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }

}

.flex-column {
  flex-direction: column;
}

.hidden {
  display: none;
}

.mat-flat-button.mat-primary {
  min-width: 0;
  outline: none;
  background-color: $color-blue;
  border-radius: 3px;

  &:hover {
    background-color: darken($color-blue, 10%);
  }

}

.mat-flat-button.mat-primary.btn-link {
  $buttonWidth: 50px;
  $buttonHeight: 36px;
  padding: 0;
  height: $buttonHeight;
  width: $buttonWidth;
  color: $color-blue;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    color: darken($color-blue, 10%);
  }

  .mat-button-wrapper,
  mat-icon {
    display: inline-block;
    height: $buttonHeight;
    width: $buttonWidth;
  }

}

button.search-button {
  line-height: 36px;
  text-align: center;

  mat-spinner {
    display: inline-block;

    circle {
      stroke: $color-blue !important;
    }

  }

}

button:disabled {
  cursor: not-allowed !important;

  > .mat-button-wrapper {
    > .action-link {
      color: $color-gray-dark;

      &:hover {
        cursor: not-allowed !important;
        text-decoration: none !important;
      }
    }

    > .diagnosis-label:hover {
      cursor: not-allowed !important;
    }
  }
}

.upper-case {
  text-transform: uppercase;
}

.disabled-text {
  color: $color-gray-medium;
}

.datepicker-toggle-container {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: -1px;
}

vsp-icon-snackbar.warning .vsp-icon-snackbar.warning {
  background-color: lighten($alertColor, 20);
}

vsp-icon-snackbar.error .vsp-icon-snackbar.error span {
  white-space: pre;
}

/*custom Radio buttons*/
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-blue !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $color-blue !important;
}

.mat-ripple-element {
  background-color: $color-ripple-blue !important;
}

.mat-radio-button.mat-radio-disabled {
  cursor: not-allowed !important;

  .mat-radio-outer-circle {
    background-color: $disabled-background-color !important;
    border: $disabled-outline-format !important;
    cursor: not-allowed !important;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked.mat-radio-disabled {
  .mat-radio-outer-circle {
    border: $disabled-checked-radio-button-outline-format !important;
  }

  .mat-radio-inner-circle {
    background-color: $disabled-outline-color !important;
  }

  .mat-ripple-element {
    background-color: $disabled-background-color !important;
  }
}

.mat-radio-button.mat-accent.mat-radio-disabled {
  .mat-radio-label {
    cursor: not-allowed !important;
  }
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: $disabled-outline-color !important;
}

/*custom checkbox*/
.mat-checkbox:hover:not(.mat-checkbox-disabled) {

  &.mat-checkbox-checked .mat-checkbox-inner-container {
    box-shadow: $form-field-box-shadow-hover-format;
    border-radius: 25% !important;

    .mat-checkbox-frame {
      border: $form-field-hover-border-color;
    }
  }

  &:not(.mat-checkbox-checked) .mat-checkbox-inner-container {
    border: $form-field-hover-border-color;
    box-shadow: $form-field-box-shadow-hover-format;
    border-radius: 25% !important;

    .mat-checkbox-frame {
      border: $form-field-hover-border-color;
    }
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #FFFFFF !important;
  // As per the angular update we needed to set these two fields to 0 px since it was moving checkbox outside the field.
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: 16px;
  height: 16px;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-radius: 25% !important;
}

.mat-checkbox-checkmark-path {
  stroke: #77B820 !important;
  stroke-width: 5px !important;
}

.mat-checkbox-inner-container .mat-ripple-element {
  background-color: $color-ripple-blue !important;
}

.mat-checkbox.mat-checkbox-checked:not(.mat-checkbox-disabled) {
  .mat-checkbox-inner-container {
    border-width: 1px !important;
  }

  .mat-checkbox-frame {
    border-color: $required-outline-color !important;
    background-color: $required-outline-color !important;
  }

  .mat-checkbox-background {
    background-color: $required-outline-color !important;

    .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
      stroke: $color-white !important;
    }
  }
}

.mat-checkbox-disabled {
  cursor: not-allowed !important;

  .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
    background-color: $disabled-background-color !important;
    border: $disabled-outline-format !important;
  }
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container {
  .mat-checkbox-frame {
    background-color: $color-gray-dark !important;
    cursor: not-allowed !important;
  }

  .mat-checkbox-background {
    background-color: $color-gray-dark !important;

    .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
      stroke: $color-white !important;
    }
  }

}

// Mat table headers
.mat-header-cell, button.mat-sort-header-button {
  font-size: $bodyFontSize;
  font-weight: bold;
}

mat-header-row {
  min-height: 49px !important;
}

// Mimic mat-table border bottom. mat-able doesn't support datasource length 0 yet, so have to set these manually
.no-records {
  min-height: 4.8rem;
  background-color: $gridBackgroundColor;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.hardEditTextBoxField {
  color: red !important;
  opacity: 1 !important;
}

.hardEditRadioButtonField {
  border: 2px red solid !important;
  border-radius: 4% !important;
}

.mat-checkbox > label > .mat-checkbox-inner-container > .hardEditCheckBoxField {
  border-color: red !important;
  border-width: thick !important;
}

.hardEditDropDownField {
  border: 2px red solid !important;
}

.softEditTextBoxField {
  color: orange !important;
  opacity: 1 !important;
}

.softEditRadioButtonField {
  border: 2px orange solid !important;
  border-radius: 4% !important;
}

.mat-checkbox > label > .mat-checkbox-inner-container > .softEditCheckBoxField {
  border-color: orange !important;
  border-width: thick !important;
}

.softEditDropDownField {
  border: 2px orange solid !important;
}

.successfully-submitted-popup .mat-dialog-container {
  background-color: lightgrey;
  border-style: solid;
  border-color: purple;
  border-width: 8px;
}

// ECR-9426 adding this override to this angular material class to solve a weird issue where opening a modal dialog is
// causing the background website to be hidden.
.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: $disabled-background-color;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: black transparent transparent !important;
}

.eclaim-root-container {
  max-width: 1170px !important;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

.eclaim-footer-container {
  margin-top: 20px;
}

.mat-radio-label-content {
  padding-left: 10px !important;
}

.card-header > h1 {
  font-family: $font-family;
  font-size: 20px;
  color: #FFFFFF;
}

.card > .card-header {
  height: 50px;
  margin-bottom: 0;
  background-color: $color-blue;
  border-bottom: 1px solid #DFDFDF;

  first-child {
    border-radius: 3px;
  }
}

.mat-card-header {
  div {
    h1 {
      font-family: $font-family;
      font-size: 20px;
      color: $color-white;
      margin: 0;
    }

    h2 {
      font-family: $font-family;
      font-size: 14px;
      color: $color-white;
      margin: 0;
    }
  }

  .mat-card-header-text {
    width: 100%;
  }
}

.mat-card > .mat-card-header {
  &.disabled-card-header {
    background-color: $color-gray-dark;
  }

  height: 50px;
  margin-bottom: 0;
  margin-top: -1px !important;
  margin-left: -1px;
  margin-right: -1px;
  background-color: $color-blue;
  border-bottom: 1px solid #DFDFDF;
  border-radius: 3px 3px 0 0;
  padding: .75rem 1.25rem;

  first-child {
    border-radius: 3px;
  }
}

.mat-card > .mat-card-content {
  margin-bottom: 0;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-list .mat-list-item {
  font-family: $font-family;
  font-size: $font-family-size;
  color: $font-family-color;
}

.eclaim-body-text {
  font-family: $font-family;
  font-size: $font-family-size;
  color: $font-family-color;
}

.eclaim-body-text-bold {
  font-family: $font-family;
  font-size: $font-family-size;
  color: $font-family-color;
  font-weight: bold;
}

.mat-list-item {
  font-family: $font-family;
  font-size: $font-family-size;
  color: $font-family-color;
}

.help-circle {
  width: 16px !important;
  height: 16px !important;

  &:hover {
    cursor: pointer;
  }

  &:focus > svg > path {
    fill: $color-blue-3 !important;
  }

  &.disabled-help-circle {
    &:hover {
      cursor: not-allowed;
    }

    &:active {
      pointer-events: none;
    }
  }

  &.disabled-help-circle > svg > path {
    fill: $color-gray-dark !important;
  }
}

.mat-card {
  color: $font-family-color;
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12) !important;
  font-family: $font-family !important;
}

.mat-card:hover {
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.eclaim-header-text {
  font-family: $font-family !important;
  font-size: 20px !important;
  color: $color-gray-dark !important;
  font-weight: bold;
}

.eclaim-sub-header-text {
  font-family: $font-family !important;
  font-size: 14px !important;
  color: $color-gray-dark !important;
}

mat-radio-button > label > .mat-radio-label-content > span {
  font-family: $font-family;
  font-size: 14px;
  color: $color-gray-dark;
}

.footer-text {
  font-family: $font-family;
  font-size: 12px;
  color: $color-gray-dark;
}

#header-divider {
  color: #DFDFDF;
}

.mat-flat-button.mat-primary {
  color: #FFFFFF;
  font-family: $font-family;
  font-size: 16px;
  border: 1px solid $color-dark-blue;
}

.mat-flat-button.mat-primary:hover {
  background-color: #004F81;
  border: 1px solid $color-dark-blue;
}

.mat-flat-button.mat-primary[disabled] {
  background-color: $color-gray-dark;
  color: #FFFFFF;
  font-family: $font-family;
  font-size: 16px;
  border: 1px solid $color-gray-dark;
}

.mat-stroked-button.mat-primary {
  color: $color-blue;
  font-family: $font-family;
  font-size: 16px;
  margin-top: 3px;
  border: 1px solid #006EB4;
}

.mat-stroked-button.mat-primary:hover {
  color: #58595B;
  border: 1px solid $color-dark-blue;
}

.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-stroked-button.mat-primary[disabled] {
  color: #58595B;
  border: 1px solid #58595B;
}

.mat-button.mat-primary {
  color: $color-blue;
  font-family: $font-family;
  font-size: 14px;
  margin-top: 3px;
}

.mat-button.mat-primary:hover {
  color: $color-dark-blue;
  text-decoration: underline;
}

.mat-button.mat-primary .mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-button.mat-primary[disabled] {
  color: $color-blue !important;
}

.mat-radio-label:hover .mat-radio-outer-circle {
  border: 2px solid $color-blue-3;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 111, 180, 0.5);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 111, 180, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 111, 180, 0.5);
}

.sticky-card-header {
  position: -webkit-sticky;
  position: sticky;
  top: 49px;
  height: 50px;
  padding: 0;
  z-index: 3;
}

.sticky-card-header-edits {
  position: -webkit-sticky;
  position: sticky;
  top: 109px;
  height: 50px;
  padding: 0;
  z-index: 3;
}

.sticky-card-header-warning {
  position: -webkit-sticky;
  position: sticky;
  top: 169px;
  height: 50px;
  padding: 0;
  z-index: 3;
}

.sticky_sentinel {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
}

.sticky_sentinel--top {
  height: 40px;
  top: -24px;
}

.sticky_sentinel--bottom {
  height: calc(var(--header-height) + var(--default-padding));
  bottom: 0;
}

mat-cell:first-of-type, mat-footer-cell:first-of-type, mat-header-cell:first-of-type {
  padding: 0 15px 0 15px !important;
}

mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
  padding: 0 15px 0 15px !important;
}

.nested-column-1 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(1 * 100% / 12);
}

.nested-column-2 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(2 * 100% / 12);
}

.nested-column-3 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(3 * 100% / 12);
}

.nested-column-4 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(4 * 100% / 12);
}

.nested-column-5 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(5 * 100% / 12);
}

.nested-column-6 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(6 * 100% / 12);
}

.nested-column-7 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(7 * 100% / 12);
}

.nested-column-8 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(8 * 100% / 12);
}

.nested-column-9 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(9 * 100% / 12);
}

.nested-column-10 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(10 * 100% / 12);
}

.nested-column-11 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(11 * 100% / 12);
}

.nested-column-12 {
  padding: 0 15px 0 15px;
  flex: 0 0 100%;
  max-width: calc(12 * 100% / 12);
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: .69em 0 !important;

  input {
    padding: 0.38em 0 0 0 !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 0 !important;
}

.state-code-dropdown-format {
  .ng-select {
    .ng-clear-wrapper {
      display: none !important;
    }
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    // This is to override the default padding so we can see the full text when the user is typing
    padding-right: 28px !important;
  }
}

.ng-select.required-form-field.ng-invalid.ng-touched:not(.ng-select-disabled) {
  &.ng-select-focused > div {
    box-shadow: $form-field-invalid-box-shadow-focus
  }

  &.ng-select-focused:not:hover > div {
    box-shadow: none !important;
  }

  > div {
    background-color: $color-red-4 !important;
    border: $form-field-invalid-border;

    &:hover:not([disabled]):not(:focus-within) {
      box-shadow: $form-field-invalid-box-shadow-focus;
      transition-duration: 0.3s !important;
      border: $form-field-invalid-border;
    }
  }
}

.ng-select {
  &.ng-select-disabled {
    .ng-arrow-wrapper {
      cursor: not-allowed !important;
      background: $disabled-select-dropdown-arrows-format;

      .ng-arrow {
        border-color: transparent transparent transparent !important;
      }
    }

    > .ng-select-container {
      border: $disabled-outline-format !important;
      background-color: $disabled-background-color !important;
      color: $font-family-color !important;
      cursor: not-allowed !important;

      > .ng-value-container > .ng-input > input {
        cursor: not-allowed !important;
      }
    }
  }

  .ng-select-container {
    border: 1px solid #000000 !important;
  }

  .ng-clear-wrapper {
    color: #000000 !important;
  }

  .ng-clear-wrapper {
    color: $color-blue !important;
  }

  .ng-arrow-wrapper {
    background: $required-and-enabled-select-dropdown-arrows-format;

    .ng-arrow {
      border-color: transparent transparent transparent !important;
    }
  }

  &.required-form-field:not(.ng-select-disabled) > div {
    background-color: $required-background-color;
    border: $required-outline-format !important;
    color: $font-family-color;
    font-family: $font-family;
    font-size: $font-family-size;
    cursor: pointer !important;

    > .ng-value-container > .ng-input > input {
      cursor: pointer !important;
    }
  }

  &:not(.ng-select-disabled) {
    &:hover:not(:focus-within) > div {
      color: $font-family-color !important;
      transition-duration: $form-field-box-shadow-transition-duration;
      box-shadow: $form-field-box-shadow-hover-format;
      border-radius: 3px !important;
      border: $form-field-hover-border-color;
    }
  }

  &.required-form-field.ng-select-focused > div {
    box-shadow: $required-box-shadow-outline-focus-format
  }

  &.required-form-field.ng-select-focused:not:hover > div {
    box-shadow: none !important;
  }

  &.ng-select-focused:not(.ng-select-opened) > div {
    box-shadow: none !important;
  }
}

.eclaim-popup-modal .mat-dialog-container {
  padding: 0;
  overflow: hidden !important;

  hr {
    margin: 0;
  }

  h1 {
    font-weight: bold;
    padding: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 20px !important;
  }

  label {
    color: $color-gray-dark;
    width: fit-content;
    font-family: $font-family;
    font-size: $font-family-size;
    margin: 20px;
    padding: 0;
  }

  .mat-icon {
    fill: $color-white;
    font-weight: normal;
    height: 14px;
    width: 14px;
  }

  .mat-icon-button ::ng-deep .mat-button-focus-overlay {
    display: none;
    padding: 0;
  }

  .mat-icon-button {
    width: 0;
    height: 0;
  }

  .modal-footer {
    padding: 0;
  }

  .close-button {
    float: right;
    top: -10px;
    right: 20px;
    border: 0;
    outline: none !important;
  }

  .modal-margin-borders {
    margin: 20px;
    padding: 0;
  }

  .cancel-button {
    min-width: 0;
    outline: none !important;
    color: $color-blue;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    font-family: $font-family;
    font-weight: bold;
    padding: 0;
    margin: 0;

    &:hover {
      color: $color-blue;
      background-color: $color-white;
    }
  }
  .mat-card-content {
    margin-bottom: 0 !important;
  }

  .mat-card-actions {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.mat-card-title {
  margin-bottom: 0 !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $color-blue !important;
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}

.sticky-footer-popup-modal {

  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }

  .mat-dialog-actions {
    margin-bottom: 0 !important;
    justify-content: flex-end;
    padding: 20px 20px !important;
  }

  .nested-column-12 {
    padding: 0 !important;
  }

  .header-content {
    height: 50px;
    margin-bottom: 0;
    margin-top: -1px !important;
    margin-left: -1px;
    margin-right: -1px;
    background-color: $color-blue;
    border-bottom: 1px solid #DFDFDF;
    border-radius: 3px 3px 0 0;
    padding: .75rem 1.25rem;

    h1 {
      font-family: $font-family;
      font-size: 20px;
      font-weight: bold;
      color: $color-white;
      margin: 0;
    }
  }

  .close-button {
    float: right;
    top: -10px;
    right: 20px;
    border: 0;
    outline: none !important;

    .mat-icon {
      fill: $color-white;
      font-weight: normal;
      height: 14px;
      width: 14px;
      margin-left: -20px;
    }
  }

  a {
    padding-left: -20px !important;
  }

  .cancel-button {
    min-width: 0;
    outline: none !important;
    color: $color-blue;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    font-family: $font-family;
    font-weight: bold;
    padding: 0;
    margin: 0;

    &:hover {
      color: $color-blue;
      background-color: $color-white;
    }
  }
}

.mat-checkbox, .mat-radio-button {
  font-family: $font-family;
}

.secondary-cta-button {
  min-width: 0;
  outline: none !important;
  color: $font-family-secondary-cta-color;
  background-color: transparent;
  border: 0;
  font-family: $font-family-secondary-cta;
  font-size: $font-family-secondary-cta-size;
  margin-top: 3px;

  &:hover {
    color: $font-family-secondary-cta-hover-color;
    text-decoration: underline;
  }
}

.mat-paginator, .mat-paginator-page-size, .mat-select-value, .mat-option-text,
.mat-paginator-navigation-next, .mat-paginator-page-size-label,
.mat-option.mat-selected:not(.mat-option-disabled)  {
  font-family: $font-family !important;
  font-size: $font-family-size !important;
  color: $font-family-color !important;
}

.mat-table {
  font-family: $font-family;
}

.tooltip-font {
  font-family: $font-family;
}
