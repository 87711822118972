

/** START - FONT SIZE VARS **/
// Font size should be kept in pixels for resizing of spacing (via zoom), which is kept in rems
$display1FontSize: 30px;
$display2FontSize: $display1FontSize;
$display3FontSize: 40px;
$display4FontSize: $display3FontSize;
$display5FontSize: 50px;
$display6FontSize: $display5FontSize;
$heading1FontSize: 24px;
$heading2FontSize: 20px;
$heading3FontSize: 18px;
$subheadFontSize: 16px;
$bodyFontSize: 14px;
$captionFontSize: 12px;
$subcaptionFontSize: 10px;
/** START - FONT SIZE VARS **/


/** START - SIZE VARS **/
$appWidth: 1170px;
$appWidthRem: 117rem;

$extraSmallSpacer: 1rem; // 10px
$smallSpacer: 2rem; // 20px
$mediumSpacer: 3rem; // 30px
$largeSpacer: 4rem; // 40px
$extraLargeSpacer: 5rem; // 50px

$iconSize: 2.4rem; // 24px
$smallIconSize: 1.8rem; // 18px

$tabHeight: 4.4rem; // 44px

$gridSpacing: 2.4rem; // 24px
$gridRowHeight: 4.8rem; // 48px

$borderSize: 1px;
/** END - SIZE VARS **/


/** START - COLOR VARS **/
$primaryColor1: #005D83;
$primaryColor2: #E0E0E0;
$primaryColor3: #FAFAFA;
$primaryColor4: #FFF;
$primaryColor5: #000;
$primaryColor6: #757575;
$primaryColor7: #CECECE;
$primaryColor8: #F2F2F2;
$primaryColor9: #D50001;
$primaryColor10: #298600;
$primaryColor11: #83388E;
$primaryColor12: #BD5B01;
$primaryColor13: #003046;

$errorColor: $primaryColor9;
$alertColor: $primaryColor12;
$pendingColor: $primaryColor12;
$activeColor: $primaryColor10;
$accentColor: $primaryColor11;
$focusColor: $primaryColor13;

$borderColor: $primaryColor2;
$border: $borderSize solid $borderColor;
$darkBackgroundColor: $primaryColor3;
$lightBackgroundColor: $primaryColor4;

$actionableColor: $primaryColor1;
$actionableHoverColor: rgba($actionableColor, 0.15);
$disabledColor: $primaryColor6;
$disabledBackgroundColor: $primaryColor7;
$textColor: $primaryColor5;
$alternateTextColor: $primaryColor4;

$iconColor: $primaryColor5;
$iconActionableColor: $actionableColor;
$iconDisabledColor: $disabledColor;

$gridBackgroundColor: $primaryColor4;
$gridAlternateBackgroundColor: $primaryColor8;
/** END - COLOR VARS **/


/** START - ANIMATION VARS **/
$animationDuration: 250ms;
$fullAnimation: 250ms ease-in-out;
/** END - ANIMATION VARS **/
